import React from 'react';
import GoogleLoginButton from './GoogleLoginButton';
import FacebookLoginButton from './FacebookLoginButton';
import TwitterLoginButton from './TwitterLoginButton';
import { Row } from 'react-bootstrap';


const Login = () => {
	return <div>
    <Row>
      <GoogleLoginButton />
    </Row>
    <Row>
      <FacebookLoginButton />
    </Row>
    <Row>
      <TwitterLoginButton />
    </Row>
  </div>;
};

export default Login;

import React from 'react';
import { Card, Button } from 'react-bootstrap';





const RegionSelector = ({ regions }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      {regions.map(region => (
        <Card key={region.id} style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{region.full_name}</Card.Title>
            <Card.Text>
              ID: {region.id}<br />
              Name: {region.name}<br />
              State: {region.state}<br />
              Motto: {region.motto || 'N/A'}<br />
              Primary: {region.is_primary ? 'Yes' : 'No'}<br />
              Short Name: {region.short_name || 'N/A'}<br />
              Latitude: {region.lat}<br />
              Longitude: {region.lon}<br />
              Subregion: {region.subregion}<br />
              Country: {region.country}<br />
              Created At: {region.created_at}<br />
              Updated At: {region.updated_at}
            </Card.Text>
            <Button variant="primary">Select</Button>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default RegionSelector;
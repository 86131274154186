import React from 'react';
import { Card } from 'react-bootstrap';


const RegistrationComplete = () => {
	return <div>
    <Card> Registration Recieved!</Card><Card> Wait for a text about your next tournament!
      </Card> </div>;
};

export default RegistrationComplete;

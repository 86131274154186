import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const RegisterButton = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate('/registration/player/basic');
  };

  return (
    <Card>
      <button className="btn btn-primary" onClick={handleOnClick}>
        Register
      </button>      
    </Card>
  );
};

export default RegisterButton;

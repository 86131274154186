import React from 'react';

const LogoutButton = () => {
  const handleLogout = () => {
    // Remove specific items from localStorage
    localStorage.removeItem('registrationCode');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('loggedIn');
    window.location.reload();

    // Additional logout actions can be added here
    // For example, redirecting the user to a different page or updating the app state
  };

  return (
    <button onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;

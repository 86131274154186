import React, {useState} from 'react';
import {Form, Button, Card, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './RegBasic.css';

const RegBasic = () => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		phoneNumber: '',
	});

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData((prevState) => ({...prevState, [name]: value}));
	};

  function sanitizeInput(input) {
    return input.trim().replace(/^[^a-zA-Z0-9]*|[^a-zA-Z0-9]*$/g, '');
  }

	function validatePhoneNumber(phoneNumber) {
		const digits = phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
		if (digits.length === 10) {
			// Standard 10-digit phone number
			return digits;
		} else if (digits.length === 11 && digits.startsWith('1')) {
			// 11-digit phone number starting with '1' (country code)
			return digits.substring(1);
		} else {
			return null; // Invalid phone number format
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
    formData.firstName = sanitizeInput(formData.firstName);
    formData.lastName = sanitizeInput(formData.lastName);

		formData.phoneNumber = validatePhoneNumber(formData.phoneNumber);
		if (formData.phoneNumber === null) {
			alert('Please enter a valid phone number');
			return; // Stop the form submission
		} else {
			// URL of your Cloudflare service worker endpoint
			const workerUrl = 'https://player-registration-form-basic.everyonespinballclub.workers.dev/basicRegistration';
      // test this in the worker with this: {"firstName":"Ryan","lastName":"Dunn","phoneNumber":"9164256173","route":"basicRegistration"}
      
			try {
				console.log(formData); // Here you will handle the form submission
				var data = {...formData, route: 'basicRegistration'};
        console.log(JSON.stringify(data));
				const response = await fetch(workerUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				});

				if (response.ok) {
					const responseData = await response.json();
					console.log('Success:', responseData);
					handleShow(true);

					setFormData({firstName: '', lastName: '', phoneNumber: ''}); // Clear form
				} else {
					console.error('Response Error:', response.statusText);
					// Handle errors returned from your worker
				}
			} catch (error) {
				console.error('Network Error:', error);
				// Handle network errors
			}
		}
	};

	return (
		<Card className="border-primary mb-3 form-container mt-5">
			<Card.Header className="bg-primary text-white text-center">
				<h4>Player Registration</h4>
			</Card.Header>
			<Card.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="formFirstName">
						<Form.Label className="float-start">First Name</Form.Label>
						<Form.Control type="text" name="firstName" placeholder="Pinball" value={formData.firstName} onChange={handleInputChange} required />
					</Form.Group>

					<Form.Group className="mb-3" controlId="formLastName">
						<Form.Label className="float-start">Last Name</Form.Label>
						<Form.Control type="text" name="lastName" placeholder="Wizard" value={formData.lastName} onChange={handleInputChange} required />
					</Form.Group>

					{/* Phone Number */}
					<Form.Group className="mb-3" controlId="formPhoneNumber">
						<Form.Label className="float-start">Phone Number</Form.Label>
						<Form.Control type="tel" name="phoneNumber" placeholder="555-555-5555" value={formData.phoneNumber} onChange={handleInputChange} required />
					</Form.Group>

					{/* Submit Button */}
					<div className="text-center">
						<Button variant="primary" type="submit">
							Register
						</Button>
						<Modal show={show} onHide={handleClose}>
							<Modal.Header closeButton>
								<Modal.Title>Registration</Modal.Title>
							</Modal.Header>
							<Modal.Body>CHECK YOUR PHONE! Click the link in the text to complete registration.</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
						</Modal>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export default RegBasic;

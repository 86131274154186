import React, {useState, useEffect} from 'react';
import './LocationAutocomplete.css'; // Import the CSS file for styling

const LocationAutocomplete = () => {
	const [inputValue, setInputValue] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [expandedId, setExpandedId] = useState(null);
	const [expandedData, setExpandedData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	//const [error, setError] = useState(null);
	const [locationData, setLocationData] = useState({});
	const [machinesList, setMachinesList] = useState([]);

	useEffect(() => {
		if (inputValue.length >= 3) {
			setIsLoading(true);
			fetch(`https://pinballmap.com/api/v1/locations/autocomplete?name=${encodeURIComponent(inputValue)}`)
				.then((response) => response.json())
				.then((data) => {
					setSearchResults(data || []);
					setIsLoading(false);
				})
				.catch((error) => {
					console.error('Error fetching autocomplete data:', error);
					setSearchResults([]);
					setIsLoading(false);
				});
		} else {
			setSearchResults([]);
		}
	}, [inputValue]);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const parseLocationLabel = (label) => {
		const regex = /^(.*?)\s*\(([^)]+)\)$/;
		const match = label.match(regex);
		return match ? {name: match[1], area: match[2]} : {name: label, area: ''};
	};

	const handleCardClick = (id) => {
		if (id === expandedId) {
			setExpandedId(null);
			setExpandedData({});
			setMachinesList([]);
		} else {
			setIsLoading(true);
			fetch(`https://pinballmap.com/api/v1/locations/${id}/machine_details.json?machines_only=true`)
				.then((response) => response.json())
				.then((data) => {
					setMachinesList(data.machines || []);
					setIsLoading(false);
					// Proceed to fetch expanded data
					fetchExpandedData(id);
				})
				.catch((error) => {
					console.error('Error fetching machines list:', error);
					setMachinesList([]);
					setIsLoading(false);
				});

			fetch(`https://pinballmap.com/api/v1/locations/${id}`)
				.then((response) => {
					if (!response.ok) {
						throw new Error('Failed to fetch location data');
					}
					return response.json();
				})
				.then((data) => {
					console.log(data);
					setLocationData(data);
					setIsLoading(false);
				})
				.catch((error) => {
					console.error('Error fetching data:', error);
					// setError(error.toString());
					setIsLoading(false);
				});
		}
	};

	const fetchExpandedData = (id) => {
		fetch(`https://pinballmap.com/api/v1/locations/${id}/machine_details.json`)
			.then((response) => response.json())
			.then((data) => {
        console.log(`id: ${id}`);
        console.log(data);
				setExpandedId(id);
				setExpandedData(data);
			})
			.catch((error) => console.error('Error fetching location details:', error));
	};

	return (
		<div className="autocomplete-card">
			<input type="text" value={inputValue} onChange={handleInputChange} placeholder="Search locations..." />
			{isLoading && <p>Loading...</p>}
			<div className="location-results">
				{searchResults.map((location, index) => {
					const {name, area} = parseLocationLabel(location.label);
					const isExpanded = location.id === expandedId;
					return (
						<div key={index} className={`location-card ${isExpanded ? 'expanded' : ''}`} onClick={() => handleCardClick(location.id)}>
							<p>
								<strong>Name:</strong> {name}
							</p>
							<p>
								<strong>Area:</strong> {area}
							</p>
							{isExpanded && expandedData && locationData && machinesList && (
								<div className="expanded-content">
									<p>
										<strong>Description:</strong> {locationData.description || 'N/A'}
									</p>
									<p>
										<strong>ID:</strong> {locationData.id}
									</p>
									<p>
										<strong>Street:</strong> {locationData.street || 'N/A'}
									</p>
									<p>
										<strong>City:</strong> {locationData.city || 'N/A'}
									</p>
									<p>
										<strong>State:</strong> {locationData.state || 'N/A'}
									</p>
									<p>
										<strong>Zip:</strong> {locationData.zip || 'N/A'}
									</p>
									<p>
										<strong>Stern Army:</strong> {locationData.is_stern_army ? `yes` : `no` || 'N/A'}
									</p>
									<p>
										<strong>Website:</strong> {locationData.website || 'N/A'}
									</p>
									<p>
										<strong>Number of Machines:</strong> {locationData.num_machines || 'N/A'}
									</p>

									{machinesList.map((machine, index) => {
										// If machine is a string
										return <p key={`${machine}-${index}`}>{machine}</p>;
                    

										// If machine is an object with an id
										// return <p key={machine.id}>{machine.name}</p>;
									})}
                   {/*ipdb_id={expandedData.machines[index].ipdb_id}*/}
									{/* Add other fields as needed */}
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default LocationAutocomplete;

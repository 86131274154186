import React from 'react';
import './ChatBot.css';

const ChatBot = () => {
  return (
    <div className="chat-box">
      <iframe 
        src="/chatbot.html" 
        title="Chatbot" 
        width="100%" 
        height="1000px" // Adjust size as needed
      ></iframe>
    </div>
  );
};

export default ChatBot;
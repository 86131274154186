import React from 'react';
import {Card, Row} from 'react-bootstrap';

import FacebookLoginButton from '../oauth/FacebookLoginButton';
import TwitterLoginButton from '../oauth/TwitterLoginButton';
import GoogleLoginButton from '../oauth/GoogleLoginButton';

const NewPlayer = () => {
	// Extract 'code' from URL parameters
	const urlParams = new URLSearchParams(window.location.search);
	const registrationCode = urlParams.get('code');
	localStorage.setItem('registrationCode', registrationCode);

	return (
		<div>
			<Card className="border-primary mb-3 form-container mt-5">
				<Card.Header className="bg-primary text-white text-center">
					<h4>Create Account</h4>
				</Card.Header>
				<Card.Body>
					<Row>
						<GoogleLoginButton />
					</Row>
					<Row>
						<FacebookLoginButton />
					</Row>
					<Row>
						<TwitterLoginButton />
					</Row>
				</Card.Body>
			</Card>
		</div>
	);
};

export default NewPlayer;

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppRoutes from './Routes'; // Import the routes

function App() {
  return (
    <Router>
      <div className="App">
        {/* <TopBar /> */}
        <AppRoutes /> {/* Use the AppRoutes component */}
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import RegionSelector from '../components/RegionsSelector';
import ZoneSelector from '../components/ZoneSelector';



//'Https://pinballmap.com/api/v1/regions.json'

const Test = () => {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch('https://pinballmap.com/api/v1/regions.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const sortedRegions = data.regions.sort((a, b) => a.full_name.localeCompare(b.full_name));
        setRegions(sortedRegions);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
  
    fetchRegions();
  }, []);
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;


	return (
    <div>
      <RegionSelector regions={regions}/>
      <ZoneSelector />
    </div>
  );
};

export default Test;

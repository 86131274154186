import React, {useState, useEffect} from 'react';
import {Form, Card, Button, Tooltip, OverlayTrigger, Modal, Row, Col} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import PlayerProfileCard from '../../components/PlayerProfileCard';
import {useNavigate} from 'react-router-dom';

const ConfirmRegPlayer = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		initials: '',
		ifpaId: '',
		matchplayId: '',
		phoneNumber: '',
		email: '',
		birthdate: '',
		stateProvince: '',
		region: '',
		zone: '',
		tournamentLocations: [''],
		registrationCode: '',
    
	});

	// state variables. this is how save data in react
	const [tempData, setTempData] = useState();
	const [showLocations, setShowLocations] = useState(false);
	const [locationData, setLocationData] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [zones, setZones] = useState([]);

	const [filteredRegions, setFilteredRegions] = useState([]); // State to store filtered regions

	useEffect(() => {
		const handlePlayerDataSearch = async (registrationCode,sessionToken) => {
			// URL of your Cloudflare service worker endpoint
			const workerUrl = 'https://player-registration-form-basic.everyonespinballclub.workers.dev/';
			// test this in the worker with this: {"route":"startConfirmRegistration","registrationCode":"9899d18b", "sessionToken": sessionToken}
			var data = {route: 'startConfirmRegistration', registrationCode: registrationCode, sessionToken: sessionToken};
			console.log(data);
			try {
				const response = await fetch(workerUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				});



				if (response.ok) {
					const responseData = await response.json();

					const registrationData = responseData.message.registrationData;
					const ifpaSearchData = responseData.message.ifpaSearchData;
					const ifpaPlayerInfo = responseData.message.ifpaPlayerInfo;
					const matchplayData = responseData.message.matchplayData;
					const playerData = responseData.message.playerData;

					console.log('');
					console.log('registrationData');
					console.log(registrationData);
					console.log('');
					console.log('ifpaSearchData');
					console.log(ifpaSearchData);
					console.log('');
					console.log('ifpaPlayerInfo');
					console.log(ifpaPlayerInfo);
					console.log('');
					console.log('matchplayData');
					console.log(matchplayData);
					console.log('');
					console.log('playerData');
					console.log(playerData);
					console.log('');

					/*
				// Extract region from matchplayData.location
				let region = '';
				if (playerData && playerData.location) {
					if (playerData.location.includes('Sacramento' || 'Citrus Heights')) {
						region = 'Sacramento';
					} else if (playerData.location.includes('Reno' || 'Sparks')) {
						region = 'Reno';
					} else if (playerData.location.includes('San Francisco' || 'Concord' || 'Sebastapol')) {
						region = 'Bay Area';
					} else {
						region = '';
					}
				}4
        */

					setTempData({
						firstName: registrationData.firstName || '',
						lastName: registrationData.lastName || '',
						initials: playerData.initials || '',
						ifpaId: playerData.ifpaId || '',
						matchplayId: playerData.matchplayId || '',
						phoneNumber: registrationData.phoneNumber || '',
						stateProvince: '',
						region: '',
						zone: '',
						email: '',
						birthdate: '',
						registrationCode:
							(function () {
								const urlParams = new URLSearchParams(window.location.search);
								const registrationCode = urlParams.get('code');
								return registrationCode;
							})() || '',

						tournamentLocations: [''],
					});
				} else {
					console.log('Response Error:', response.statusText);
					// Handle errors returned from your worker
				}
			} catch (error) {
				console.log('Network Error:', JSON.stringify(error));
			}
		};

		// IF we got here then the registration code should be in the local storage
		//const urlParams = new URLSearchParams(window.location.search);
		//const registrationCode = urlParams.get('code');
		//localStorage.setItem('registrationCode', registrationCode);
    const registrationCode = localStorage.getItem('registrationCode');
    const sessionToken = localStorage.getItem('sessionToken');
		// Call your async function and pass 'code' to it
		if (registrationCode) {
			handlePlayerDataSearch(registrationCode,sessionToken);
		} else {
      navigate('/registration/player/basic');
    }
	}, [navigate]);

	// Effect to load the sample data into the form
	useEffect(() => {
		if (tempData) {
			setFormData(tempData);
		}
	}, [tempData]);

	useEffect(() => {
		if (formData.region === 'Sacramento') {
			setShowLocations(true);
		} else if (formData.region && formData.region !== 'Sacramento') {
			setShowLocations(false);
		}
	}, [formData.region]);

	const handleSelectAllChange = (e) => {
		const checked = e.target.checked;
		setSelectAll(checked);
		if (checked) {
			// Select all locations
			setFormData((prevState) => ({
				...prevState,
				tournamentLocations: locationData.map((loc) => loc.id.toString()),
			}));
		} else {
			// Deselect all locations
			setFormData((prevState) => ({
				...prevState,
				tournamentLocations: [],
			}));
		}
	};

	const handleLocationCheckboxChange = (e) => {
		const {value, checked} = e.target;
		setFormData((prevState) => {
			const newLocations = checked ? [...prevState.tournamentLocations, value] : prevState.tournamentLocations.filter((id) => id !== value);

			// Update selectAll state
			const allSelected = newLocations.length === locationData.length;
			setSelectAll(allSelected);

			return {...prevState, tournamentLocations: newLocations};
		});
	};

	const handleInputChange = (e) => {
		const {name, value, type, checked} = e.target;
		setFormData({...formData, [name]: value});
		if (name === 'region') {
			const selectedRegion = regions.find((region) => region.fullName === value);
			if (selectedRegion) {
				//(selectedRegion.name);
				fetchZones(selectedRegion.name);
			}
			fetchZones(value); // Fetch zones for the selected region
			if (value === 'Sacramento') {
				setShowLocations(true);
				fetchLocationData(); // Fetch and set location data
			} else {
				setShowLocations(false);
				setLocationData([]); // Reset location data
				//setShowMessage(false);
			}
		}

		if (name === 'stateProvince') {
			setFormData((prevState) => ({...prevState, stateProvince: value, region: '', zone: ''}));
			const filtered = filterRegionsByState(regions, value);
			setFilteredRegions(filtered);
			setZones([]); // Reset zones
		} else if (name === 'region') {
			// When Region changes, reset and reload Zone
			setFormData((prevState) => ({...prevState, region: value, zone: ''}));
			const selectedRegion = regions.find((region) => region.fullName === value);

			setZones([]); // Reset zones
			if (selectedRegion) {
				fetchZones(selectedRegion.name);
			}
			// Additional logic for specific regions (Sacramento, Reno, etc.)
			// Update this part as per your specific requirements
			if (value === 'Sacramento') {
				setShowLocations(true);
				fetchLocationData();
			} else {
				setShowLocations(false);
				setLocationData([]); // Reset location data
			}
		}

		if (type === 'checkbox') {
			setFormData((prevState) => ({
				...prevState,
				tournamentLocations: checked ? [...prevState.tournamentLocations, value] : prevState.tournamentLocations.filter((loc) => loc !== value),
			}));
		} else {
			setFormData((prevState) => ({...prevState, [name]: value}));
		}
	};

	function sanitizeInput(input) {
		return input.trim().replace(/^[^a-zA-Z0-9]*|[^a-zA-Z0-9]*$/g, '');
	}

	const fetchLocationData = async () => {
		//TODO need to move this to a cloud flare kv storage.
		var clubLocations = [18502, 3038, 5771, 16200, 18269];
		//TODO need to move this to a cloud flare kv storage.

		try {
			//TODO need fetch regions dynamically as they are selected int the menu.
			const response = await fetch('https://pinballmap.com/api/v1/region/sacramento/locations.json');
			//TODO need fetch regions dynamically as they are selected int the menu.

			if (response.ok) {
				const data = await response.json();
				const filteredData = data.locations.filter((location) => clubLocations.includes(location.id)).map(({id, name, num_machines}) => ({id, name, num_machines}));

				setLocationData(filteredData);
			} else {
				console.error('Response Error:', response.statusText);
			}
		} catch (error) {
			console.error('Network Error:', error);
		}
	};

	function validatePhoneNumber(phoneNumber) {
		const digits = phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
		if (digits.length === 10) {
			// Standard 10-digit phone number
			return digits;
		} else if (digits.length === 11 && digits.startsWith('1')) {
			// 11-digit phone number starting with '1' (country code)
			return digits.substring(1);
		} else {
			return ''; // Invalid phone number format
		}
	}
	//const [selectedRegionName, setSelectedRegionName] = useState('');
	const [regions, setRegions] = useState([]);
	const [states, setStates] = useState([]); // State for storing the list of states
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const fetchZones = async (regionName) => {
		try {
			console.log(regionName);
			const response = await fetch(`https://pinballmap.com/api/v1/region/${regionName}/zones.json`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();

			setZones(data.zones);
		} catch (error) {
			console.error('Failed to fetch zones:', error);
		}
	};
	useEffect(() => {
		const fetchRegions = async () => {
			try {
				const response = await fetch('https://pinballmap.com/api/v1/regions.json');
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const data = await response.json();
				const sortedRegions = data.regions.sort((a, b) => a.full_name.localeCompare(b.full_name));
				setRegions(sortedRegions);
				setLoading(false);
			} catch (error) {
				setError(error.message);
				setLoading(false);
			}
		};

		fetchRegions();
	}, [error]);

	useEffect(() => {
		if (regions.length > 0) {
			const statesList = [...new Set(regions.map((region) => region.state))].sort();
			setStates(statesList);
		}
	}, [regions]); // This useEffect only runs when regions change

	// Handler for state/province selection
	/*
	const handleStateChange = (e) => {
		formData.stateProvince = e.target.value;
		if (formData.stateProvince) {
			const filteredRegions = filterRegionsByState(regions, formData.stateProvince);
			setFilteredRegions(filteredRegions);
		} else {
			setFilteredRegions([]);
		}
	};
  */
	if (loading) {
		return <div>Loading...</div>;
	}
	if (error) {
		return <div>Error: {error}</div>;
	}
	function filterRegionsByState(regions, state) {
		try {
			const filteredRegions = regions
				.filter((region) => region.state === state)
				.map((region) => ({id: region.id, fullName: region.full_name}))
				.sort((a, b) => a.fullName.localeCompare(b.fullName));
			console.log(filteredRegions);
			return filteredRegions;
		} catch (error) {
			console.error('Error:', error);
		}
	}
	const handleRegisterClick = async () => {
		console.log('Form Data:', formData);
		//
		// need to store the Ids of the club locations in the KV and set up the form to pull that data.
		//
		//
		//
		// this properly sends the data to console.
		// need to send it back to the worker
		// have the worker check the registrationCode against the KV registration code. ensure they match
		// store the entire array into the KV.
		// delete the registration code from the KV
		//
		//
		//
		//
		//

		// URL of your Cloudflare service worker endpoint
		const workerUrl = 'https://completeregistration.everyonespinballclub.workers.dev/';

		console.log('here');
    const sessionToken = localStorage.getItem('sessionToken');
		try {
			var data = {...formData, sessionToken: sessionToken};
			//console.log(JSON.stringify(data));
			const response = await fetch(workerUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			console.log(response);
			if (response.ok) {
				const responseData = await response.json();
				console.log('Success:', responseData);
				//handleShow(true);
				//setFormData({firstName: '', lastName: '', phoneNumber: ''}); // Clear form
			} else {
				console.error('Response Error:', response.statusText);
				// Handle errors returned from your worker
			}
		} catch (error) {
			console.error('Network Error:', error);
			// Handle network errors
		}

		alert('Registration Complete! you will recieve a text about your next tournament!');
		// formData
		setFormData({
			firstName: '',
			lastName: '',
			initials: '',
			ifpaId: '',
			matchplayId: '',
			phoneNumber: '',
			email: '',
			birthdate: '',
			stateProvince: '',
			region: '',
			zone: '',
			tournamentLocations: [''],
			registrationCode: '',
		});
    navigate('/dashboard');
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		formData.firstName = sanitizeInput(formData.firstName);
		formData.lastName = sanitizeInput(formData.lastName);
		formData.initials = sanitizeInput(formData.initials);
		formData.phoneNumber = validatePhoneNumber(formData.phoneNumber);
		if (formData.phoneNumber === '') {
			alert('Please enter a valid phone number');
			return; // Stop the form submission
		} else {
			console.log(formData);

			// Send the data to your worker endpoint
		}
	};

	return (
		<Card className="border-primary mb-3 form-container mt-5">
			
			{formData.ifpaId && formData.matchplayId && <PlayerProfileCard ifpaId={formData.ifpaId} matchplayId={formData.matchplayId} />}
			<Card.Header className="bg-primary text-white text-center">
				<h4>Player Registration</h4>
			</Card.Header>
			<Card.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="formFirstName">
						<Form.Label className="float-start">
							<span style={{color: 'red'}}>*</span>First Name
						</Form.Label>
						<Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
					</Form.Group>

					<Form.Group className="mb-3" controlId="formLastName">
						<Form.Label className="float-start">
							<span style={{color: 'red'}}>*</span>Last Name
						</Form.Label>
						<Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
					</Form.Group>

					<Form.Group className="mb-3" controlId="formInitials">
						<Form.Label className="float-start">
							<span style={{color: 'red'}}>*</span>High Score Initials
						</Form.Label>
						<Form.Control type="text" name="initials" value={formData.initials} onChange={handleInputChange} required />
					</Form.Group>

					<Form.Group className="mb-3" controlId="formIFPA">
						<Form.Label className="float-start">IFPA #</Form.Label>
						<Form.Control type="text" name="ifpaId" value={formData.ifpaId} placeholder="optional" onChange={handleInputChange} />
					</Form.Group>

					<Form.Group className="mb-3" controlId="formMatchplayId">
						<Form.Label className="float-start">MatchPlay #</Form.Label>
						<Form.Control type="text" name="matchplayId" value={formData.matchplayId} placeholder="optional" onChange={handleInputChange} />
					</Form.Group>

					<Form.Group className="mb-3" controlId="formPhoneNumber">
						<Form.Label className="float-start">
							<span style={{color: 'red'}}>*</span>Phone Number<span style={{marginLeft: '5px'}}></span>
							<OverlayTrigger key="right" placement="right" overlay={<Tooltip id={`tooltip-right`}>We'll text you for tournament scheduling, reminders, and check-in</Tooltip>}>
								<FontAwesomeIcon icon={faQuestionCircle} style={{color: 'blue', cursor: 'pointer'}} />
							</OverlayTrigger>
						</Form.Label>
						<Form.Control type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} required />
					</Form.Group>

					<Form.Group className="mb-3" controlId="formEmail">
						<Form.Label className="float-start">
							<span style={{color: 'red'}}>*</span>Email<span style={{marginLeft: '5px'}}></span>
							<OverlayTrigger key="right" placement="right" overlay={<Tooltip id={`tooltip-right`}>Used for contact when phone is unavailable</Tooltip>}>
								<FontAwesomeIcon icon={faQuestionCircle} style={{color: 'blue', cursor: 'pointer'}} />
							</OverlayTrigger>
						</Form.Label>
						<Form.Control type="email" name="email" value={formData.email} onChange={handleInputChange} required />
					</Form.Group>

{/*
					<Form.Group className="mb-3" controlId="formBirthdate">
						<Form.Label className="float-start">
							<span style={{color: 'red'}}>*</span>Birthdate<span style={{marginLeft: '5px'}}></span>
							<OverlayTrigger key="right" placement="right" overlay={<Tooltip id={`tooltip-right`}>needed for auto-scheduling at age-restricted venues</Tooltip>}>
								<FontAwesomeIcon icon={faQuestionCircle} style={{color: 'blue', cursor: 'pointer'}} />
							</OverlayTrigger>
						</Form.Label>
						<Form.Control type="date" name="birthdate" value={formData.birthdate} onChange={handleInputChange} required />
					</Form.Group>
  */}
					<Form.Group className="mb-3" controlId="formStateProvince">
						<Form.Label className="float-start">
							<span style={{color: 'red'}}>*</span>State/Province
						</Form.Label>
						<Form.Select name="stateProvince" value={formData.stateProvince} onChange={handleInputChange} required>
							<option value="">Select a State/Province</option>
							{states.map((state, index) => (
								<option key={index} value={state}>
									{state}
								</option>
							))}
						</Form.Select>
					</Form.Group>

					{formData.stateProvince && (
						<Form.Group className="mb-3" controlId="formRegion">
							<Form.Label className="float-start">
								<span style={{color: 'red'}}>*</span>Region
							</Form.Label>
							<Form.Select name="region" value={formData.region} onChange={handleInputChange} required>
								<option value="">Select a Region</option>
								{filteredRegions.map((region) => (
									<option key={region.id} value={region.fullName}>
										{region.fullName}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					)}

					{zones.length > 0 && (
						<Form.Group className="mb-3" controlId="formZone">
							<Form.Label className="float-start">
								<span style={{color: 'red'}}>*</span>Zone
							</Form.Label>
							<Form.Select name="zone" value={formData.zone} onChange={handleInputChange} required>
								<option value="">Select a Zone</option>
								{zones.map((zone) => (
									<option key={zone.id} value={zone.name}>
										{zone.name}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					)}

					{/*
					<Form.Group className="mb-3" controlId="formRegion">
						<Form.Label className="float-start">
							<span style={{color: 'red'}}>*</span>Region
						</Form.Label>
						<Form.Select name="region" value={formData.region} onChange={handleInputChange} required>
							<option value="">Select a Region</option>
							<option value="Reno">Reno</option>
							<option value="Sacramento">Sacramento</option>
							<option value="San Francisco">San Francisco</option>
							<option value="Other">Other...</option>
						</Form.Select>
					</Form.Group>

            */}
					{showLocations ? (
						<Form.Group className="mb-3">
							<Row>
								<Form.Label className="float-start">Tournament Locations</Form.Label>
							</Row>
							<Row className="align-items-center">
								<Col xs={1}>
									<input type="checkbox" id="select-all-locations" checked={selectAll} onChange={handleSelectAllChange} />
								</Col>
								<Col xs={11}>
									<label htmlFor="select-all-locations">Select All</label>
								</Col>
							</Row>
							<div>
								{locationData.map((location) => (
									<Row key={location.id} className="align-items-center">
										<Col xs={1}>
											<input type="checkbox" id={`location-${location.id}`} name="tournamentLocations" value={location.id} checked={formData.tournamentLocations.includes(location.id.toString())} onChange={handleLocationCheckboxChange} />
										</Col>
										<Col xs={8}>
											<label htmlFor={`location-${location.id}`}>{location.name}</label>
										</Col>
										<Col xs={3}>{`${location.num_machines} machines`}</Col>
									</Row>
								))}
							</div>
						</Form.Group>
					) : (
						formData.region &&
						formData.region === 'Reno' && (
							<div className="alert alert-info mt-3">
								Exciting news Reno!<br></br> Your region is on the list for our upcoming chapters. Stay tuned for a text notification when it's live and ready!
							</div>
						)
					)}
					<div className="text-center">
						<Button onClick={handleRegisterClick} variant="primary" type="submit">
							Register
						</Button>
					</div>
				</Form>
			</Card.Body>

			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Uh oh...</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* Your content for the modal */}
					<p>The club isn't in your region yet...</p>
					<p>But we probably will be soon.</p>

					{/* You can add a form field or any other content here */}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowModal(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Card>
	);
};

export default ConfirmRegPlayer;

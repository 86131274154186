import React from 'react';
import RegisterButton from '../components/Register';
import SessionVerification from './oauth/SessionVerification';
import LoginButton from '../components/LoginButton';
import LogoutButton from '../components/LogoutButton';


const Home = () => {
	return <div>
    <RegisterButton />
    <SessionVerification />
    
    <LoginButton />
      <LogoutButton />
  </div>;
};

export default Home;

import React, {useEffect, useState} from 'react';
import {Card, Col, ListGroup, ListGroupItem, Row} from 'react-bootstrap';

const PlayerProfileCard = ({ifpaId,matchplayId}) => {
	const [player, setPlayer] = useState(null);
	//const [tournaments, setTournaments] = useState(null);
	useEffect(() => {
		const getPlayerData = async () => {
			try {
				var url = `https://getdata.everyonespinballclub.workers.dev/ifpa/player/info?ifpaId=${ifpaId}`;
				const response = await fetch(url);
				const data = (await response.json()).player[0];
				console.log(JSON.stringify(data));
				setPlayer(data);
			} catch (error) {
				console.error('Error fetching player data:', error);
			}
		};
    
    /*
		const getUsersUpcomingTournaments = async () => {
			try {
				var url = `https://next.matchplay.events/api/tournaments?played=${matchplayId}&status=active`;
				const response = await fetch(url);
				const data = (await response.json()).data;
				console.log(JSON.stringify(data));
				setTournaments(data);
			} catch (error) {
				console.error('Error fetching tournament data:', error);
			}
		};
*/
    
    
		getPlayerData();
    //getUsersUpcomingTournaments();
	}, [ifpaId,matchplayId]);

	if (!player) {
		return <div></div>;
	}

	return (
		<Card style={{width: '18rem'}}>
			<Card.Img variant="top" src={player.profile_photo} />
			<Card.Body>
				<Card.Title>{`${player.first_name} ${player.last_name}`}</Card.Title>
				<Card.Text>
					{player.city} {player.stateprov} {player.country_name}
				</Card.Text>
			</Card.Body>
			<ListGroup className="list-group-flush">
				<ListGroupItem>
					<Row>
						<Col>Initials:</Col>
						<Col> {player.initials}</Col>
					</Row>
				</ListGroupItem>
				<ListGroupItem>
					<Row>
						<Col>Age:</Col>
						<Col> {player.age}</Col>
					</Row>
				</ListGroupItem>

				<ListGroupItem></ListGroupItem>
				<ListGroupItem>IFPA</ListGroupItem>
				<ListGroupItem>
					<Row>
						<Col>Current Rank:</Col>
						<Col> {player.player_stats.current_wppr_rank}</Col>
					</Row>
				</ListGroupItem>
				<ListGroupItem>
					<Row>
						<Col>Active WPPRs:</Col>
						<Col> {player.player_stats.active_wppr_points}</Col>
					</Row>
				</ListGroupItem>

				<ListGroupItem>
					<Row>
						<Col>Profile: </Col>
						<Col>
							<Card.Link href={`https://www.ifpapinball.com/player.php?p=${player.player_id}`}>{`${player.player_id}`}</Card.Link>
						</Col>
					</Row>
				</ListGroupItem>
				<ListGroupItem>
					<Row>
						<Col>Ratings Rank: </Col>
						<Col>{player.player_stats.ratings_rank}</Col>
					</Row>
				</ListGroupItem>

				<ListGroupItem></ListGroupItem>
				<ListGroupItem>Matchplay.Events</ListGroupItem>
				<ListGroupItem>
					<Row>
						<Col>Profile: </Col>
						<Col>
							<Card.Link href={`https://www.next.matchplay.events/users/${player.matchplay_events.id}`}>{`${player.matchplay_events.id}`}</Card.Link>
						</Col>
					</Row>
				</ListGroupItem>
				<ListGroupItem>
					<Row>
						<Col>Rating: </Col>
						<Col>{player.matchplay_events.rating}</Col>
					</Row>
				</ListGroupItem>
				<ListGroupItem>
					<Row>
						<Col>Rank: </Col>
						<Col>{player.matchplay_events.rank}</Col>
					</Row>
				</ListGroupItem>
        {/*}
				<ListGroupItem>
          {tournaments.data}
				</ListGroupItem>
  */}
				{/* Add more list group items for other relevant data */}
			</ListGroup>
			<Card.Body></Card.Body>
		</Card>
	);
};

export default PlayerProfileCard;

import React, {useEffect, useState} from 'react';

import { useNavigate } from 'react-router-dom';

const SessionVerification = () => {
  const navigate = useNavigate();
	const [userStatus, setUserStatus] = useState(null);

	useEffect(() => {
		const verifySession = async () => {
			const sessionToken = localStorage.getItem('sessionToken');
			const registrationCode = localStorage.getItem('registrationCode');
      var data = null;
			if (sessionToken) {
				try {
					const response = await fetch('https://verifysession.everyonespinballclub.workers.dev/', {
						method: 'GET',
						headers: {
							'x-session-token': sessionToken,
							//'x-registration-code': registrationCode,
						},
					});

					data = await response.json();
					setUserStatus(data.userInfo);
          console.log(data.userInfo);
				} catch (error) {
					console.error('Error verifying session:', error);
				}
			} else {
				setUserStatus({userRegistered: false, userId: null, userLoggedIn: false});
			}
      if(data.userInfo.userLoggedIn===true && data.userInfo.userRegistered===false){
        if(registrationCode){
          navigate(`/confirmreg?code=${registrationCode}`);
        } else {
          navigate(`/registration/player/basic`);
        }
        
      }
		};
    
		verifySession();
	}, [navigate]);

	if (!userStatus) {
		return <div>Verifying session..</div>;
	}


	return (
		<div>
    <div>{userStatus.userRegistered ? <div>User is registered.</div> : <div>User is not registered.</div>}</div>
    <div>{userStatus.userLoggedIn ? <div>User is logged in.</div> : <div>User is not logged in.</div>}</div>
    <div>{userStatus.userId ? <div>UserId: {userStatus.userId} </div> : <div>User has no id.</div>}</div>
		</div>
	);
};

export default SessionVerification;

import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{ textAlign: 'left', padding: '0 20px' }}>
      <h1>Terms of Service for Everyone's Pinball Club</h1>

      <h2>1. Introduction</h2>
      <p>Welcome to Everyone's Pinball Club. By accessing our website or using our services, you agree to be bound by these terms of service. If you do not agree to all of these terms, do not use our services.</p>

      <h2>2. Use of Service</h2>
      <p>Our services are intended for users who are at least 18 years old. By using our services, you represent and warrant that you are of legal age to form a binding contract with Everyone's Pinball Club.</p>

      <h2>3. Changes to Terms</h2>
      <p>We reserve the right to modify or replace these terms at any time, and will provide notice of any significant changes. Continued use of our services after any such changes constitutes your acceptance of the new terms.</p>

      <h2>4. User Accounts</h2>
      <p>When you create an account with us, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password.</p>

      <h2>5. Prohibited Uses</h2>
      <p>You may use our service only for lawful purposes and in accordance with these terms. You agree not to use the service in any way that violates any applicable local or international law or regulation.</p>

      <h2>6. Termination</h2>
      <p>We may terminate or suspend access to our service immediately, without prior notice, for any reason whatsoever, including without limitation if you breach the terms.</p>

      <h2>7. Governing Law</h2>
      <p>These terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

      <h2>8. Contact Information</h2>
      <p>If you have any questions about these terms, please contact us at info@everyonespinballclub.com.</p>

      {/* Additional terms and conditions specific to your service can be added here */}
    </div>
  );
};

export default TermsOfService;

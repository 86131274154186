import React, { useEffect, useState } from 'react';
import SessionVerification from './oauth/SessionVerification';
import LoginButton from '../components/LoginButton';
import LogoutButton from '../components/LogoutButton';
import LocationSelector from '../components/LocationSelector';

const Dashboard = () => {
  const sessionToken = localStorage.getItem('sessionToken');
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);

  // START getting userId from session token
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://authreq.everyonespinballclub.workers.dev/', {
          method: 'GET',
          headers: {
            'x-session-token': sessionToken,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const userId = await response.json();
        setUserId(userId); // Store the entire JSON object
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    if (sessionToken) {
      fetchData();
    }
  }, [sessionToken]);
  // END getting userId from session token

  // START getting user data from userId & session token
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://authreq.everyonespinballclub.workers.dev/', {
          method: 'POST',
          headers: {
            'x-session-token': sessionToken,
          },
          body: JSON.stringify({            
            "action": "read",
            "userId": userId,               // need to do this differently because
            //namespace: 'users',           // update to do it like this!!!
            //namespaceId: ##,              // update to do it like this!!!
            "field": "all",
          }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const responseData = await response.json();
        setUserData(responseData); // Store the entire JSON object
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    if (sessionToken) {
      fetchData();
    }
  }, [sessionToken,userId]);
  // END getting user data from userId & session token



  return (
    <div>
      <h1>Dashboard</h1>
      {sessionToken && <p>Session Token: {sessionToken}</p>}
      {!sessionToken && <p>No session token found.</p>}

      {userData ? (
        <div>
          <p>UserId: {userData.userId}</p>
          <p>Name: {userData.name}</p>
          <p>First Name: {userData.firstName}</p>
          <p>Last Name: {userData.lastName}</p>
          <p>Registration Code: {userData.registrationCode}</p>
          <p>Email (Google): {userData.registrationGoogle}</p>
          <p>Email (Facebook): {userData.registrationFacebook}</p>
          <p>Email (Twitter): {userData.registrationTwitter}</p>
          <p>Matchplay ID: {userData.matchplayId}</p>
          <p>IFPA ID: {userData.ifpaId}</p>
          <p>Role: {userData.role || 'Not specified'}</p>
          <p>Location: {userData.location || 'Not specified'}</p>
          <p>State/Province: {userData.stateProvince}</p>
          <p>Region: {userData.region}</p>
          <p>Zone: {userData.zone}</p>
          <p>Email: {userData.email}</p>
          <p>Birthdate: {userData.birthdate || 'Not specified'}</p>
          <p>Pronouns: {userData.pronouns || 'Not specified'}</p>
          <p>Initials: {userData.initials}</p>
          <p>Phone Number: {userData.phoneNumber}</p>
          <p>Tournament Locations: {userData.tournamentLocations.join(', ')}</p>
          <LocationSelector locations={userData.tournamentLocations} />
          {/* Add any additional fields you might have */}
        </div>
      ) : (
        <p>Loading user data...</p>
      )}

      <SessionVerification />
      <LoginButton />
      <LogoutButton />
    </div>
  );
};

export default Dashboard;

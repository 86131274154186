import {faFacebook} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';

// eslint-disable-next-line
if (!env) {
	var env = process.env;
} // diferentiates between local and production environments

const mainAppOrigin = 'https://thewizpinball.pages.dev' || 'http://localhost:3000';

const FACEBOOK_APP_ID = '779175320685773';

const FacebookLoginButton = () => {
	const [loading, setLoading] = useState(false);
	const handleLogin = () => {
		setLoading(true);
		const width = 600,
			height = 600;
		const left = (window.innerWidth - width) / 2;
		const top = (window.innerHeight - height) / 2;

		const redirectUri = encodeURIComponent('https://oauth-facebook.everyonespinballclub.workers.dev/auth/callback');

		const url = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${redirectUri}&scope=email,public_profile&response_type=code`;
		const windowFeatures = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;
		const oauthWindow = window.open(url, 'FacebookLogin', windowFeatures);

		/*
        const oauthInterval = window.setInterval(() => {
          if (oauthWindow.closed) {
              window.clearInterval(oauthInterval);
              setLoading(false);
              window.location.href = `${mainAppOrigin}/dashboard`;
          }
        }, 6000);
        */

		window.addEventListener(
			'message',
			(event) => {
				if (event.origin === 'https://thewizpinball.pages.dev' || 'http://localhost:3000' || 'https://everyonespinballclub.com') {
					const {type, sessionToken} = event.data;
					if (type === 'oauth-success') {
						console.log('OAuth Success, Session Token:', sessionToken);
						localStorage.setItem('sessionToken', sessionToken);
						window.location.href = `${mainAppOrigin}/dashboard`;
						oauthWindow.close();
					}
				}
			},
			false
		);
	};

	return (
		<div>
			<button className="btn btn-primary mb-2" onClick={handleLogin} disabled={loading}>
				{loading ? 'Logging in...' : 'Login with Facebook '}
				<FontAwesomeIcon className="" icon={faFacebook} size="lg" color="white" />
			</button>
		</div>
	);
};

export default FacebookLoginButton;

import React from 'react';



const PrivacyPolicy = () => {
  const style = {
    textAlign: 'left', // Align text to the left
    padding: '0 20px'  // Add some padding on both sides
  };
  return (
    <div style={style}>
      <h1>Privacy Policy for Everyone's Pinball Club</h1>
      <p>Last Updated: November 25, 2023</p>

      <h2>Welcome to Everyone's Pinball Club</h2>
      <p>We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>

      <h2>1. Important Information and Who We Are</h2>
      <h3>Purpose of This Privacy Policy</h3>
      <p>This privacy policy aims to give you information on how Everyone's Pinball Club collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to our newsletter, purchase a product or service, or take part in a competition.</p>

      <h3>Controller</h3>
      <p>Everyone's Pinball Club is the controller and responsible for your personal data (collectively referred to as "Everyone's Pinball Club", "we", "us", or "our" in this privacy policy).</p>

      <h3>Contact Details</h3>
      <p>If you have any questions about this privacy policy or our privacy practices, please contact us in the following ways:</p>
      <p>Full name of legal entity: Everyone's Pinball Club</p>
      <p>Email address: info@everyonespinballclub.com</p>

      <h2>2. The Data We Collect About You</h2>
      <p>We may collect, use, store, and transfer different kinds of personal data about you which we have grouped together as follows:</p>
      <ul>
        <li>Identity Data includes first name, last name, username or similar identifier.</li>
        <li>Contact Data includes email address and telephone numbers.</li>
        <li>Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
        <li>Usage Data includes information about how you use our website, products, and services.</li>
        <li>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
      </ul>

      <h2>3. How Is Your Personal Data Collected?</h2>
      <p>We use different methods to collect data from and about you including through:</p>
      <ul>
        <li>Direct interactions. You may give us your Identity and Contact by filling in forms or by corresponding with us by post, phone, email, or otherwise.</li>
        <li>Automated technologies or interactions. As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions, and patterns.</li>
      </ul>

      <h2>4. How We Use Your Personal Data</h2>
      <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
      <ul>
        <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
        <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
        <li>Where we need to comply with a legal obligation.</li>
      </ul>

      <h2>5. Data Security</h2>
      <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered, or disclosed.</p>

      <h2>6. Data Retention</h2>
      <p>We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements.</p>

      <h2>7. Your Legal Rights</h2>
      <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, restriction, transfer, to object to processing, to portability of data, and (where the lawful ground of processing is consent) to withdraw consent.</p>

      <h2>8. Glossary</h2>
      <h3>LAWFUL BASIS</h3>
      <ul>
        <li>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience.</li>
        <li>Compliance with a legal obligation means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;

import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';

const mainAppOrigin = 'https://thewizpinball.pages.dev' || 'http://localhost:3000';

const TwitterLoginButton = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	//const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			//setLoading(true);
			try {
				const response = await fetch('https://oauth-twitter.everyonespinballclub.workers.dev/');
				const data = await response.text();
				setData(data);
				//setError(null);
			} catch (err) {
				console.log(err);
				//setError(err);
			}
		};
		fetchData();
	}, []);

	const handleLogin = () => {
		setLoading(true);
		//if (error) return <div>Error: {error.message}</div>;
		const width = 600,
			height = 600;
		const left = (window.innerWidth - width) / 2;
		const top = (window.innerHeight - height) / 2;

		const url = data;
		const windowFeatures = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;
		const oauthWindow = window.open(url, 'TwitterLogin', windowFeatures);
		

		/*
    const oauthInterval = window.setInterval(() => {
      if (oauthWindow.closed) {
          window.clearInterval(oauthInterval);
          setLoading(false);
          window.location.href = `${mainAppOrigin}/dashboard`;
      }
    }, 6000);
    */

		window.addEventListener(
			'message',
			(event) => {
        console.log(event.origin);
				if (event.origin === 'https://thewizpinball.pages.dev'||'http://localhost:3000'||'https://everyonespinballclub.com') {
          console.log(event)
					const {type, sessionToken} = event.data;
					if (type === 'oauth-success') {
						console.log('OAuth Success, Session Token:', sessionToken);
						localStorage.setItem('sessionToken', sessionToken);
						window.location.href = `${mainAppOrigin}/dashboard`;
						oauthWindow.close();
					}
				}
			},
			false
		);
	};

	return (
    <div>
			<button className="btn btn-primary mb-2" onClick={handleLogin} disabled={loading}>
				{loading ? 'Logging in...' : 'Login with Twitter '}
        <FontAwesomeIcon className="" icon={faTwitter} size="lg" color="white" />
			</button>
      </div>
	);
};

export default TwitterLoginButton;


import React from 'react';
import { Card, Button } from 'react-bootstrap';

//https://pinballmap.com/api/v1/region/Sacramento/locations.json


const LocationSelector = ({ locations }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      {locations.map(location => (
        <Card key={location.id} style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{location.name}</Card.Title>
            <Card.Text>
              Address: {location.street}, {location.city}, {location.state}, {location.zip}<br />
              Phone: {location.phone || 'N/A'}<br />
              Website: {location.website || 'N/A'}<br />
              Latitude: {location.lat}<br />
              Longitude: {location.lon}<br />
              Country: {location.country}<br />
              Created At: {location.created_at}<br />
              Updated At: {location.updated_at}<br />
              Zone ID: {location.zone_id}<br />
              Region ID: {location.region_id}<br />
              Location Type ID: {location.location_type_id}<br />
              Description: {location.description || 'N/A'}<br />
              Operator ID: {location.operator_id || 'N/A'}<br />
              Date Last Updated: {location.date_last_updated}<br />
              Last Updated By User ID: {location.last_updated_by_user_id}<br />
              Is Stern Army: {location.is_stern_army ? 'Yes' : 'No'}<br />
              IC Active: {location.ic_active ? 'Yes' : 'No'}<br />
              Last Updated By Username: {location.last_updated_by_username}<br />
              Number of Machines: {location.num_machines}
            </Card.Text>
            <Button variant="primary">Select</Button>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default LocationSelector;

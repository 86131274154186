import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import RegBasic from './pages/registration/RegBasic';
import ConfirmRegPlayer from './pages/registration/ConfirmRegPlayer';
import Test from './pages/Test';
import ChatBot from './components/ChatBot';
import RegistrationComplete from './pages/registration/registrationComplete';
import PrivacyPolicy from './policy/PrivacyPolicy';
import TermsOfService from './policy/TermsOfService';
import Login from './pages/oauth/login';
import Dashboard from './pages/Dashboard';
import NewPlayer from './pages/registration/NewPlayer';
import LocationAutocomplete from './components/LocationSearchAutoComplete';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/registration/player/basic" element={<RegBasic />} />
      <Route path="/confirmreg" element={<ConfirmRegPlayer />} />
      <Route path="/wizTD" element={<ChatBot />} />
      <Route path="/test" element={<Test />} exact />
      <Route path="/registration/complete" element={<RegistrationComplete />} exact />
      <Route path="/policy/privacy" element={<PrivacyPolicy />} />
      <Route path="/policy/terms" element={<TermsOfService />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/newplayer" element={<NewPlayer />} />
      <Route path="/locationAutoComplete" element={<LocationAutocomplete />} />
    </Routes>
  );
};

export default AppRoutes;
